<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col
          cols="7"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              class="d-inline-block"
              placeholder="Pesquise por título ou ID"
            />
          </div>
        </b-col>
        <b-col
          cols="5"
          md="2"
          class="offset-md-4"
        >
          <b-button
            variant="primary"
            block
            :to="{ name: 'product-create'}"
          >
            Novo
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-table
      sticky-header
      :items="items"
      responsive
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >

      <template #cell(id)="data">
        <span class="text-center">
          {{ data.item.id }}
        </span>
      </template>

      <template #cell(title)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              v-if="data.item.image"
              size="32"
              :src="data.item.image"
            />
            <b-avatar v-else variant="light-secondary">
              <feather-icon icon="ImageIcon" />
            </b-avatar>
          </template>
          <span class="d-block text-nowrap">
            <b-icon
              style="width: 9px;"
              icon="circle-fill"
              :class="data.item.status ? 'text-success' : 'text-muted'"
              :id="`product-row-${data.item.id}-status-icon`"
            />
            <b-tooltip
              :title="data.item.status ? 'Publicado' : 'Despublicado'"
              class="cursor-pointer"
              :target="`product-row-${data.item.id}-status-icon`"
            />
            {{ data.item.title }}
          </span>
        </b-media>
      </template>
      <template #cell(total)="data">
        {{ data.item.amount }}
      </template>
      <template #cell(action)="data">
        <feather-icon
          icon="EyeIcon"
          size="16"
          class="cursor-pointer cursor"
          @click="$router.push({ name: 'order-preview', params: { uuid: data.item.id }})"
        />
      </template>
    </b-table>

    <div class="mx-2 mb-2 mt-2 paginate-area">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">
            Exibindo 1 de 10 com total de 100 resultados
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0 mt-1 mt-sm-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BIcon, BRow, BCol, BFormInput,
  BTable, BButton, BPagination, BAvatar, BMedia, BTooltip,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCard,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BAvatar,
    BMedia,
    BTooltip,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 1,
      rows: 200,
      fields: [
        {
          key: 'category',
          label: 'Categoria',
          sortable: true,
          class: 'text-left',
        },
        {
          key: 'total',
          label: 'total',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'count',
          label: 'Quant.',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
          thStyle: 'width: 80px',
        },
      ],
      items: [
        {
          action: '',
          category: 'Curso Online',
          count: '108',
          amount: 'R$ 451.214,54',
          date: '01/01/2021 às 15:10',
        },
        {
          action: '',
          category: 'Curso Presencial',
          count: '451',
          amount: 'R$ 121.098,30',
          date: '01/01/2021 às 15:10',
        },
      ],
    };
  },
  directives: {
    Ripple,
  },
};
</script>
